<template>
  <div style="height: 100%">
    <div class="item_title headerHeight">
      <div class="item_titleline"></div>
      企业名片
    </div>
    <div class="content">
      <div class="commom_title_card">
        <div class="logo">
          <a-row>
            <a-col :span="12" style="width: 95%">
              <a-row>
                <a-col :span="1">
                  <div class="commom_line"></div>
                </a-col>
                <a-col :span="6">企业logo</a-col>
              </a-row>
            </a-col>
            <a-col :span="12" style="width: 5%">
              <div style="">
                <a-button type="primary" @click="onclick" v-perms="'upload'">上传图片</a-button>
              </div>
            </a-col>
          </a-row>
          <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-row>
              <a-col :span="8">
                <a-form-model-item label="公司logo">
                  <div style="width: 130px; height: 130px; border-radius: 50% 50%; overflow: hidden" v-if="companylogo">
                    <img
                      @click="photoBig(companylogo)"
                      :src="companylogo"
                      style="width: 100%; height: 100%; cursor: pointer"
                    />
                  </div>
                </a-form-model-item>
              </a-col>
              <a-col :span="16">
                <a-form-model-item label="公司相册" v-if="picList">
                  <div
                    v-for="(item, index) in picList"
                    :key="index"
                    style="width: 130px; height: 80px; display: inline-block; margin: 5px"
                  >
                    <img @click="photoBig(item)" :src="item" style="width: 100%; height: 100%; cursor: pointer" />
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <!-- 点击上传企业logo -->
          <a-modal v-model="modalVisible" width="1100px" :footer="null" @cancel="cancel">
            <p>上传企业logo</p>
            <hr style="margin-bottom: 10px" />
            <a-form-model
              ref="ruleForm"
              :model="evaluate"
              :rules="rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >
              <a-col :span="12">
                <a-form-model-item label="公司logo" prop="logo" ref="logo">
                  <a-upload
                    list-type="picture-card"
                    :file-list="evaluate.logo"
                    @change="handleChange"
                    :customRequest="uploadEvaluate"
                    accept=".png, .jpg"
                  >
                    <div v-if="evaluate.logo.length < 1">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>
                  <p><span style="color: red">*</span> 上传图片大小不超过2MB</p>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="公司相册" prop="photos" ref="photos">
                  <a-upload
                    list-type="picture-card"
                    :file-list="evaluate.photos"
                    @change="handleChange1"
                    :customRequest="uploadEvaluate1"
                    accept=".png, .jpg"
                  >
                    <div v-if="evaluate.photos.length < 5">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>
                  <p><span style="color: red">*</span> 最多可上传5张图片,每张图片大小不超过2MB</p>
                </a-form-model-item>
              </a-col>
              <div class="btn__box">
                <a-button type="primary" @click="onSubmit">上传</a-button>
                <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
              </div>
            </a-form-model>
          </a-modal>
        </div>
        <div class="enterpriseInfor">
          <a-row>
            <a-col :span="12" style="width: 95%">
              <a-row>
                <a-col :span="1">
                  <div class="commom_line"></div>
                </a-col>
                <a-col :span="6">企业信息</a-col>
              </a-row>
            </a-col>
            <a-col :span="12" style="width: 5%">
              <div style="">
                <a-button type="primary" @click="ok" v-perms="'save'">保存信息</a-button>
              </div>
            </a-col>
          </a-row>
          <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" :model="form" ref="form">
            <a-row>
              <a-col :span="8">
                <a-form-model-item label="企业名称">
                  <a-input v-model="form.enterpriseName" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="统一社会信用代码">
                  <a-input v-model="form.creditCode" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row> </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-model-item
                  label="企业类型"
                  :prop="'enterpriseType'"
                  :rules="{ required: true, message: '不能为空' }"
                >
                  <a-select
                    v-model="form.enterpriseType"
                    style="width: 100%"
                    :options="enterpriseType"
                    allowClear
                  ></a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item
                  label="企业规模"
                  :prop="'enterpriseScale'"
                  :rules="{ required: true, message: '不能为空' }"
                >
                  <a-select
                    v-model="form.enterpriseScale"
                    style="width: 100%"
                    placeholder="请选择企业规模"
                    :options="enterpriseScale"
                    allowClear
                  ></a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item
                  label="成立日期"
                  :prop="'establishmentTime'"
                  :rules="{ required: true, message: '不能为空' }"
                >
                  <a-date-picker v-model="form.establishmentTime" value-format="YYYY-MM-DD" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="16">
                <a-form-model-item
                  label="营业执照"
                  required
                  :label-col="{
                    span: 4,
                  }"
                  :wrapper-col="{
                    span: 5,
                  }"
                >
                  <div style="width: 120px; height: 200px">
                    <img
                      @click="photoBig(businessLicense)"
                      :src="businessLicense"
                      style="width: 100%; height: 100%; cursor: pointer"
                    />
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="16">
                <a-form-model-item
                  label="其他资质"
                  :label-col="{
                    span: 4,
                  }"
                >
                  <a-upload
                    list-type="picture-card"
                    :file-list="evaluate.otherLicense"
                    @change="handleChange2"
                    :customRequest="uploadEvaluate2"
                    @preview="photoBig($event.url)"
                    accept=".png, .jpg"
                  >
                    <a-icon type="plus" />
                    <div class="ant-upload-text">上传图片</div>
                  </a-upload>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="8">
                <a-form-model-item label="企业介绍" prop="description">
                  <a-textarea v-model="form.description" :auto-size="{ minRows: 2 }" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
        <div class="bank">
          <a-col :span="8" style="width: 100%">
            <a-row>
              <a-col :span="1">
                <div class="commom_line"></div>
              </a-col>
              <a-col :span="6">银行账号信息</a-col>
            </a-row>
          </a-col>
          <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-row>
              <a-col :span="8">
                <a-form-model-item label="开户行名称">
                  <a-input v-model="form.accountName" disabled />
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="银行卡号">
                  <a-input v-model="form.cardNo" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
        <div class="bank">
          <a-col :span="8" style="width: 100%">
            <a-row>
              <a-col :span="1">
                <div class="commom_line"></div>
              </a-col>
              <a-col :span="6">工资发放三方合同</a-col>
            </a-row>
          </a-col>
          <a-row>
            <a-col :span="8">
              <span style="font-size: 14px; color: #3d3d3d; padding-left: 74px">业务外包线上签署协议：</span>
              <a-button type="primary" @click="handleViewContract('YWWB')" v-perms="'ywwb-look'">{{
                enterInfo[`contractYWWB`] ? '查看' : '去签订'
              }}</a-button>
              <a-button type="primary" @click="handleViewContractDownload('YWWB')" v-perms="'ywwb-download'">下载</a-button>
            </a-col>
            <!-- <a-col :span="8">
              <span style="font-size: 14px; color: #3d3d3d; padding-left: 90px">灵活用工线上签署协议：</span>
              <a-button type="primary" @click="handleViewContract('LLYG')" v-perms="'lhyg-look'">{{
                enterInfo[`contractLLYG`] ? '查看' : '去签订'
              }}</a-button>
              <a-button type="primary" @click="handleViewContractDownload('LLYG')" v-perms="'lhyg-download'">下载</a-button>
            </a-col> -->
            <a-col :span="8">
              <span style="font-size: 14px; color: #3d3d3d; padding-left: 90px">保费缴款委托书：</span>
              <a-button type="primary" @click="handleViewContract('BXJF')" v-perms="'bfwt-look'">{{
                enterInfo[`contractBXJF`] ? '查看' : '去签订'
              }}</a-button>
              <a-button type="primary" @click="handleViewContractDownload('BXJF')" v-perms="'bfwt-download'">下载</a-button>
            </a-col>
          </a-row>
        </div>
        <!-- <div class="legal">
          <a-row>
            <a-col :span="8" style="width: 100%">
              <a-row>
                <a-col :span="1">
                  <div class="commom_line"></div>
                </a-col>
                <a-col :span="6">企业法人信息</a-col>
              </a-row>
            </a-col>
            <a-col :span="24">
              <a-form-model
                :label-col="{
                  span: 3,
                }"
                :wrapper-col="{
                  span: 5,
                }"
              >
                <a-form-model-item label="法⼈姓名">
                  <a-input v-model="enterpriseInfo.legalInfo.legalName" disabled />
                </a-form-model-item>

                <a-form-model-item label="法⼈身份证号">
                  <a-input v-model="enterpriseInfo.legalInfo.legalCardNo" disabled />
                </a-form-model-item>
                <a-form-model-item label="法⼈身份证⼈像⾯">
                  <div style="width: 290px; height: 180px">
                    <img @click="photoBig(front)" :src="front" style="width: 100%; height: 100%; cursor: pointer" />
                  </div>
                </a-form-model-item>
                <a-form-model-item label="法⼈身份证国徽⾯">
                  <div style="width: 290px; height: 180px">
                    <img @click="photoBig(back)" :src="back" style="width: 100%; height: 100%; cursor: pointer" />
                  </div>
                </a-form-model-item>
              </a-form-model>
            </a-col>
          </a-row>
        </div> -->
        <div class="center-box">
          <a-button type="primary" @click="handleReAuth" v-perms="'reset-rz'">重新认证</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadImage, getTypeDetail, enterpriseInfo } from '@/api/materialShop'
import { enterpriseCard, addEnterprisePhotoAndLogo } from '@/api/login.js'
import moment from 'moment'
import { getSalaryContract } from '@/api/recruitUse'

export default {
  data () {
    const validator = (rule, value, callback) => {
      if (value.length < 200) {
        return callback()
      }
      callback(new Error('公司介绍不允许超过200字'))
    }
    return {
      enterpriseScale: [],
      enterpriseType: [],
      businessLicense: '',
      companylogo: '',
      picList: [],
      moment,
      front: '',
      back: '',
      modalVisible: false,
      evaluate: {
        // logo
        logo: [],
        // 相册
        photos: [],
        otherLicense: []
      },
      edit: false,
      headers: {
        authorization: 'authorization-text'
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      options: [],
      cityList: [],
      other: '',
      enterpriseInfo: {
        fileList: [],
        legalInfo: {
          fileList: []
        }
      },
      form: {
        enterpriseId: undefined,
        enterpriseScale: undefined,
        enterpriseName: undefined,
        operatingAddress: undefined,
        creditCode: undefined,
        registeredCapital: undefined,
        registeredNumber: undefined,
        registrationAuthority: undefined,
        ElectronicLicense: undefined,
        enterpriseAddress: undefined,
        description: undefined,
        legalName: undefined,
        accountName: undefined,
        accountCode: undefined,
        cardNo: undefined,
        enterpriseType: undefined,
        enterpriseNature: undefined,
        establishmentTime: undefined,
        registerTime: undefined,
        operatingPeriod: undefined
      },
      rules: {
        description: [{ required: true, message: '不能为空', trigger: 'change' }, { validator: validator }],
        logo: [{ required: true, message: '请上传照片', trigger: 'change' }],
        photos: [{ required: true, message: '请上传照片', trigger: 'change' }]
      },
      enterInfo: {},
      timer: null
    }
  },
  created () {
    this.init()
    this.timer = setInterval(() => {
      enterpriseCard().then((res) => {
        this.enterInfo = res.data
      })
    }, 2000)
  },
  beforeDestroy () {
    this.timer && clearInterval(this.timer)
  },
  methods: {
    init () {
      getTypeDetail({ dictName: 'enterpriseType' }).then((res) => {
        this.enterpriseType = res.data
      })
      getTypeDetail({ dictName: 'enterpriseScale' }).then((res) => {
        this.enterpriseScale = res.data
      })
      enterpriseCard().then((res) => {
        this.enterInfo = res.data
        this.form.enterpriseId = res.data.enterpriseId
        this.form.enterpriseScale = res.data.enterpriseScale
        this.form.accountCode = res.data.bankCode
        this.form.accountName = res.data.bankName
        this.form.description = res.data.description
        this.form.cardNo = res.data.cardNo
        this.form.creditCode = res.data.creditCode
        this.form.enterpriseName = res.data.enterpriseName
        this.form.enterpriseNature = res.data.enterpriseNature
        this.form.enterpriseType = res.data.enterpriseType
        this.form.ElectronicLicense = res.data.license
        this.form.enterpriseAddress = res.data.registeredAddress
        this.form.operatingPeriod = res.data.operatingPeriod
        this.form.registeredCapital = res.data.registeredCapital
        this.form.registeredNumber = res.data.registeredNo
        this.form.registrationAuthority = res.data.registrationAuthority
        this.form.operatingAddress = res.data.operatingAddress
        this.form.establishmentTime = res.data.establishmentTime
        this.form.registerTime = res.data.registrationTime
        this.enterpriseInfo.legalInfo.legalName = res.data.legalInfo.legalName
        this.enterpriseInfo.legalInfo.legalCardNo = res.data.legalInfo.legalCardNo
        this.businessLicense = res.data.businessLicense
        this.front = res.data.legalInfo.front
        this.companylogo = res.data.logo
        this.back = res.data.legalInfo.back
        this.picList = res.data.photos.map((res) => {
          return res.url
        })
        this.evaluate.otherLicense = JSON.parse(res.data.otherLicense || '[]')
      })
    },
    ok () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { enterpriseType, enterpriseScale, description, establishmentTime, enterpriseId } = this.form
          enterpriseInfo({
            enterpriseId: enterpriseId,
            enterpriseType: enterpriseType,
            enterpriseScale: enterpriseScale,
            description: description,
            establishmentTime: establishmentTime,
            otherLicense: JSON.stringify(this.evaluate.otherLicense)
          }).then((res) => {
            this.$message.success('信息保存成功')
            this.init()
          })
        }
      })
    },
    onclick () {
      if (this.companylogo) {
        this.evaluate.logo.push({
          uid: this.evaluate.logo.length + 1,
          name: 'image.png',
          status: 'done',
          url: this.companylogo
        })
        if (this.picList) {
          this.picList.map((res) => {
            this.evaluate.photos.push({
              uid: this.evaluate.photos.length + 1,
              name: 'image.png',
              status: 'done',
              url: res
            })
          })
          this.modalVisible = true
        }
      } else {
        this.modalVisible = true
      }
    },
    cancel () {
      this.evaluate.logo = []
      this.evaluate.photos = []
      this.$refs.logo.clearValidate()
      this.$refs.photos.clearValidate()
    },
    // 上传企业logo
    handleChange ({ file, fileList }) {
      this.$set(
        this.evaluate,
        'logo',
        this.evaluate.logo.filter((item) => item.uid !== file.uid)
      )
    },
    uploadEvaluate (file) {
      if (file.file.size > 2 * 1024 * 1024) {
        this.$message.warning('图片大小不要超过2MB')
      } else {
        const form = new FormData()
        form.append('file', file.file)
        uploadImage(form)
          .then((res) => {
            this.evaluate.logo.push({
              uid: this.evaluate.logo.length + 1,
              name: 'image.png',
              status: 'done',
              url: res.path
            })
            this.$refs.logo.clearValidate()
          })
          .finally((err) => {})
      }
    },
    // 上传公司相册
    handleChange1 ({ file, fileList }) {
      this.$set(
        this.evaluate,
        'photos',
        this.evaluate.photos.filter((item) => item.uid !== file.uid)
      )
    },
    handleChange2 ({ file, fileList }) {
      this.$set(
        this.evaluate,
        'otherLicense',
        this.evaluate.otherLicense.filter((item) => item.uid !== file.uid)
      )
    },
    uploadEvaluate1 (file) {
      if (file.file.size > 2 * 1024 * 1024) {
        this.$message.warning('图片大小不要超过2MB')
      } else {
        const form = new FormData()
        form.append('file', file.file)
        uploadImage(form)
          .then((res) => {
            this.evaluate.photos.push({
              uid: this.evaluate.photos.length + 1,
              name: 'image.png',
              status: 'done',
              url: res.path
            })
            this.$refs.photos.clearValidate()
          })
          .finally((err) => {})
      }
    },
    uploadEvaluate2 (file) {
      const form = new FormData()
      form.append('file', file.file)
      uploadImage(form)
        .then((res) => {
          this.evaluate.otherLicense.push({
            uid: this.evaluate.otherLicense.length + 1,
            name: 'image.png',
            status: 'done',
            url: res.path
          })
        })
        .finally((err) => {})
    },
    beforeUpload (file) {
      const reader = new FileReader()
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      // 转化为blob
      // reader.readAsArrayBuffer(file)

      return false
    },
    onSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {
            logo: this.evaluate.logo.map((item) => item.url)[0],
            urlList: this.evaluate.photos.map((item) => item.url)
          }
          addEnterprisePhotoAndLogo(params).then((res) => {
            this.$message.success('企业logo上传成功')
            this.modalVisible = false
            this.evaluate.logo = []
            this.evaluate.photos = []
            this.init()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.ruleForm.resetFields()
      this.$set(this, 'evaluate', {
        // logo
        logo: [],
        // 相册
        photos: []
      })
    },
    go () {
      this.$router.push({ name: 'login' })
    },
    // 图片变大
    photoBig (val) {
      this.$hevueImgPreview(val)
    },
    handleReAuth () {
      this.$router.push({
        name: 'enterpriseInfor',
        query: {
          reloadAuth: true
        }
      })
    },
    handleViewContract (type) {
      this.$spin.show()
      getSalaryContract(this.$store.getters.enterpriseId)
        .then((res) => {
          if (res.data[type]) {
            window.open(res.data[type])
          } else {
            window.open(this.enterInfo[`contract${type}`])
          }
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    handleViewContractDownload (type) {
      this.$spin.show()
      getSalaryContract(this.$store.getters.enterpriseId)
        .then((res) => {
          if (res.data[type]) {
            this.$message.warning('该企业暂未签署此协议')
          } else {
            window.open(this.enterInfo[`downloadContract${type}`])
          }
        })
        .finally(() => {
          this.$spin.hide()
        })
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
</style>
<style lang="less" scoped>
.center-box {
  text-align: center;
}
.commom_title_card {
  font-size: 20px;
  font-weight: bold;
  line-height: 80px;
  color: #1677ff;
  background: #ffffff;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 4px 4px 0 0;
}
.content {
  height: calc(100% - 45px);
  overflow: auto;
}
.headerHeight {
  height: 30px;
}
.commom_line {
  width: 6px;
  height: 25px;
  background: #1677ff;
  margin: 30px auto 0;
}
.enterpriseInfor {
  background: #fff;
  padding: 0 60px;
}
.bank {
  padding: 0 60px;
}
.legal {
  padding: 0 60px;
}
.logo {
  padding: 0 60px;
}
.btn__box {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
